import { VStack, HStack, Heading, Text } from "@chakra-ui/react";
import { Link } from "gatsby";
import * as React from "react";
import { Herakles } from "../assets/herakles-logo";
import Seo from "./seo";

// https://www.freecodecamp.org/news/gatsby-blog-header-image-twitter-card/

const Layout = (props) => {
  return (
    <>
      <Seo
        title={props.title}
        image={props.image}
        description={props.description}
      ></Seo>
      <VStack maxWidth="6xl" m="0 auto" alignItems="center" px="4">
        <HStack pt="16" width="100%" spacing="4">
          <Herakles></Herakles>
          <HStack
            borderBottom="2px"
            borderColor="gray.300"
            width="100%"
            alignItems="flex-end"
            spacing="10"
            wrap="wrap"
          >
            <Heading fontSize="7xl" color="red.600" fontWeight="light">
              Herakles
            </Heading>
            <HStack pb="4" spacing="4" wrap="wrap">
              <Text as={Link} fontWeight="semibold" color="gray.600" to="/">
                Bücher
              </Text>
              <Text pb="0">•</Text>
              <Text
                as={Link}
                fontWeight="semibold"
                color="gray.600"
                to="/self-care-landing"
              >
                🌱 Self-Care
              </Text>
              <Text pb="0">•</Text>
              <Text
                as={Link}
                fontWeight="semibold"
                color="gray.600"
                to="/verlag"
              >
                Verlag
              </Text>
            </HStack>
          </HStack>
        </HStack>
        <VStack
          alignItems="center"
          spacing="20"
          width="100%"
          maxWidth="5xl"
          px="10"
          py="20"
        >
          {props.children}
          <HStack width="100%">
            <HStack borderTop="4px" borderColor="gray.200" width="100%" py="10">
              <Link to="/legal-notice/">Impressum</Link>
              <Text pb="0" color="red.600">
                •
              </Text>
              <Link to="/privacy-policy/">Datenschutz</Link>
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default Layout;
